import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IconCheck from "../icons/icon-check"
import ContactChat from "../components/contact-chat"

const ThankYouPage = () => (
    <Layout>
        <SEO title="Thank You"/>
        <div className="cols-2-1" style={{marginBottom: `3em`}}>
            <div>
                <ul class="has-inline-svg" id="thankyou-received">
                    <li><IconCheck/>Message received</li>
                </ul>
                <h1>Thank you very much for writing to me.</h1>
                <p><strong>You made my day!</strong> I will reply to you as quick as I can.</p>
                <p>Need an urgent reply? Select your preferred messaging app to start a chat with me directly.</p>
                <ContactChat/>
            </div>
        </div>
        <div className="section-highlight">
            <div className="cols-1 section">
                <h1>In the meantime...</h1>
                <p>How about following me on Facebook? You can stay updated on my latest happenings and articles.</p>
                <div id="facebook-follow">
                    <a href="https://www.facebook.com/nadzimwebdesign" target="_blank" rel="noopener noreferrer">Go to <strong>Nadzim.com</strong> on Facebook</a>
                    <p className="small-text"><em>Opens in a new tab.</em></p>
                </div>
                <div><p>Thank you again!</p></div>
            </div>
        </div>
    </Layout>
)

export default ThankYouPage